import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type {
    DoctorDetailResponse,
    IDoctorCard,
    IPagination
} from "../../models/consultationsTypes";
import { DoctorDocsResponse, IPaymentInfoPayload } from "../../models/doctorTypes";
import { UserStatus } from "../../models/userTypes";

interface IRequestState {
    total: number;
    doctors: Array<IDoctorCard>;
    pagination: IPagination;
    doctorDetail: DoctorDetailResponse;
    isLoading: boolean;
    payment: {
        percent: IPaymentInfoPayload["percent"];
        platonUserId: IPaymentInfoPayload["platon_user_id"];
    };
}

const initialState: IRequestState = {
    total: 0,
    doctors: [],
    pagination: {
        skip: 0,
        take: 10
    },
    doctorDetail: {
        about: "",
        docs: [],
        education: "",
        license: null,
        firstName: "",
        prices: {
            consultation_price: 0,
            recipe_price: 0,
            season_price: 0,
            consultation_length: 40,
            rest_length: 10
        },
        id: "",
        languages: [],
        lastName: "",
        publicUrl: "",
        specialization: [],
        surName: "",
        work_bio: "",
        bio: "",
        location_country: "",
        location_city: "",
        sex: "",
        activeStatus: UserStatus.ON_REVIEW,
        birthdate: "",
        city: "",
        email: "",
        country: "",
        phone: ""
    },
    isLoading: false,
    payment: {
        percent: 0,
        platonUserId: null
    }
};

export const requestsSlice = createSlice({
    name: "requests",
    initialState,
    reducers: {
        setDoctorsRequests(state, action: PayloadAction<IDoctorCard[]>) {
            state.isLoading = false;
            state.doctors = action.payload;
        },
        setTotal(state, action: PayloadAction<number>) {
            state.total = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setPagination(state, action: PayloadAction<IPagination>) {
            state.pagination = action.payload;
        },
        setDoctorDetails(state, action: PayloadAction<DoctorDetailResponse>) {
            state.isLoading = false;
            state.doctorDetail = action.payload;
        },
        setDoctorDocuments(state, action: PayloadAction<DoctorDocsResponse[]>) {
            state.doctorDetail.docs = action.payload;
        },
        setDoctorPercent(state, action: PayloadAction<IPaymentInfoPayload["percent"]>) {
            state.payment.percent = action.payload;
        },
        setPlatonUserId(state, action: PayloadAction<IPaymentInfoPayload["platon_user_id"]>) {
            state.payment.platonUserId = action.payload;
        },
        setPercentForDoctor(state, action: PayloadAction<{ id: string }>) {
            const currDoctor = state.doctors.find((elem) => elem.id === action.payload.id);
            if (!currDoctor) return;
            const updatedDoctorObj = { ...currDoctor, isPercent: true };
            const currentIndex = state.doctors.findIndex((elem) => elem.id === action.payload.id);
            const newDoctorsArray = [
                ...state.doctors.slice(0, currentIndex),
                updatedDoctorObj,
                ...state.doctors.slice(currentIndex + 1)
            ];
            state.doctors = newDoctorsArray;
        }
    }
});

export default requestsSlice.reducer;
