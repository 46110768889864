import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { IDoctorCard, IPagination } from "../../models/consultationsTypes";
import type { IPromoCode, PercentsResponse } from "../../models/doctorTypes";

interface IFinanceState {
    total: number;
    pagination: {
        skip: number;
        take: number;
    };
    doctors: Array<IDoctorCard>;
    isLoading: boolean;
    promoTotal: number;
    promo: IPromoCode[];
    activePromoCount: number;
    usedPromoCount: number;
    percents: PercentsResponse[];
}

const initialState: IFinanceState = {
    total: 0,
    pagination: {
        skip: 0,
        take: 10
    },
    doctors: [],
    promo: [],
    isLoading: false,
    promoTotal: 0,
    activePromoCount: 0,
    usedPromoCount: 0,
    percents: []
};

export const financeSlice = createSlice({
    name: "finance",
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setTotal(state, action: PayloadAction<number>) {
            state.total = action.payload;
        },
        setPromoTotal(state, action: PayloadAction<number>) {
            state.promoTotal = action.payload;
        },
        setPagination(state, action: PayloadAction<IPagination>) {
            state.pagination = action.payload;
        },
        setDoctorList(state, action: PayloadAction<Array<IDoctorCard>>) {
            state.isLoading = false;
            state.doctors = action.payload;
        },
        setPromoCodes(state, action: PayloadAction<IPromoCode[]>) {
            state.isLoading = false;
            state.promo = action.payload;
        },
        setPromoCount(
            state,
            action: PayloadAction<{ activePromoCount: number; usedPromoCount: number }>
        ) {
            state.activePromoCount = action.payload.activePromoCount;
            state.usedPromoCount = action.payload.usedPromoCount;
        },
        setPercents(state, action: PayloadAction<PercentsResponse[]>) {
            state.percents = action.payload;
            state.isLoading = false;
        }
    }
});

export default financeSlice.reducer;
